import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import All from '../views/All.vue'
// import Fall from '../views/Fall.vue'
// import Face from '../views/Face.vue'
// import Law from '../views/Law.vue'
// import Background from '../views/background.vue'
// import Fire from '../views/Fire.vue'
// import Car from '../views/Car.vue'
// import PeopleFace from '../views/PeopleFace.vue'
// import Bones from '../views/Bones.vue'
// import PictureText from '../views/PictureText.vue'
// import Contrast from '../views/Contrast.vue'
// import Legal from '../views/Legal.vue'
// import AI_Painting from '../views/AI_Painting.vue'
// import AI_Picture from '../views/AI_Picture.vue'
// import Portrait from '../views/Portrait.vue'
// import Big_portrait from '../views/Big_portrait.vue'
// import Remove from '../views/Remove.vue'
// import Picture from '../views/Picture_take.vue'
// import ceshi from '../views/ceshi.vue'
// import Violation from '../views/Violation.vue'
// import Trash from '../views/Trash.vue'
// import Document from '../views/Document.vue'
// import License_plate from '../views/License_plate.vue'
// import Classification from '../views/Classification.vue'
// import Line from '../views/Line.vue'
// import Parking from '../views/Parking.vue'
// import Secure from '../views/Secure.vue'
// import Trash_open from '../views/Trash_open.vue'
// import Phone from '../views/Phone.vue'
// import Smoke from '../views/Smoke.vue'
// import Wanwu from '../views/Wanwu.vue'
// import Legal1 from '../views/legal1.vue'
// import Legal2 from '../views/legal2.vue'
// import Legal3 from '../views/legal3.vue'
// import Fire1 from '../views/Fire1.vue'
// import Fire2 from '../views/fire2.vue'
// import diedao from '../views/diedao.vue'
// import dajia from '../views/dajia.vue'
// import xiyan from '../views/xiyan.vue'
// import touchi from '../views/touchi.vue'
// import juji from '../views/juji.vue'
// import shucai from '../views/shucai.vue'
// import shuiguo from '../views/shuiguo.vue'
// import zhongyao from '../views/zhongyao.vue'
// import gou from '../views/gou.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
    if ( resolve || reject ) return originalPush.call(this, location, resolve, reject)
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
//1.创建路由组件
//2.将路由和组件进行映射
// const routes = [
//   { path: '/home', component: Home },
//   { path: '/user', component: User }
// ]
const routes = [
    {
      path: '/',
      component: Main,
	  redirect:'/all',  //重定向
      children: [
        {path: '/all',name:'all',component: All}, //万物检测
  //       {path: '/fall',name:'fall',component: Fall}, //跌倒检测
		// {path: '/face',name:'face',component: Face}, //跌倒检测
		// {path: '/law',name:'law',component: Law}, //跌倒检测
		// {path: '/background',name:'background',component: Background},
		// {path: '/car',name:'car',component: Car},
		// {path: '/fire',name:'fire',component: Fire},
		// {path: '/peopleface',name:'peopleface',component: PeopleFace},
		// {path: '/bones',name:'bones',component: Bones},
		// {path: '/picturetext',name:'picturetext',component: PictureText},
		// {path: '/contrast',name:'contrast',component: Contrast},
		// {path: '/legal',name:'legal',component: Legal},
		// {path: '/Ai_painting',name:'Ai_painting',component: AI_Painting},
		// {path: '/Ai_picture',name:'Ai_picture',component: AI_Picture},
		// {path: '/portrait',name:'portrait',component: Portrait},
		// {path: '/big_portrait',name:'big_portrait',component: Big_portrait},
		// {path: '/remove',name:'remove',component: Remove},
		// {path: '/picture',name:'picture',component: Picture},
		// {path: '/ceshi',name:'ceshi',component: ceshi},
		// {path: '/Violation',name:'Violation',component: Violation},
		// {path: '/trash',name:'trash',component: Trash},
		// {path: '/document',name:'document',component: Document},
		// {path: '/license_plate',name:'license_plate',component: License_plate},
		// {path: '/classification',name:'classification',component: Classification},
		// {path: '/line',name:'line',component: Line},
		// {path: '/parking',name:'parking',component: Parking},
		// {path: '/secure',name:'secure',component: Secure},
		// {path: '/trash_open',name:'trash_open',component: Trash_open},
		// {path: '/phone',name:'phone',component: Phone},
		// {path: '/smoke',name:'smoke',component: Smoke},
		// {path: '/wanwu',name:'wanwu',component: Wanwu},
		// {path: '/legal1',name:'legal1',component: Legal1},
		// {path: '/legal2',name:'legal2',component: Legal2},
		// {path: '/legal3',name:'legal3',component: Legal3},
		// {path: '/fire1',name:'fire1',component: Fire1},
		// {path: '/fire2',name:'fire2',component: Fire2},
		// {path: '/diedao',name:'diedao',component: diedao},
		// {path: '/dajia',name:'dajia',component: dajia},
		// {path: '/xiyan',name:'xiyan',component: xiyan},
		// {path: '/touchi',name:'touchi',component: touchi},
		// {path: '/juji',name:'juji',component: juji},
		// {path: '/shucai',name:'shucai',component: shucai},
		// {path: '/shuiguo',name:'shuiguo',component: shuiguo},
		// {path: '/zhongyao',name:'zhongyao',component: zhongyao},
		// {path: '/gou',name:'gou',component: gou}
      ]
    }
  ]
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})
// const originalPush = router.prototype.push;
// router.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject)
//     return originalPush.call(this, location, onResolve, onReject);
//   return originalPush.call(this, location).catch((err) => err);
// };

export default router