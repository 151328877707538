
import axios from "axios"
import Vue from 'vue'
let vue=new Vue()
//axios.defaults.baseURL="http://123.56.12.238:11090" //德宝
//axios.defaults.baseURL="http://192.168.31.110:5000"   //测试
axios.defaults.baseURL="http://192.168.31.148:5000"
axios.defaults.timeout=50000000000000
let instance1=axios.create()

export{
	instance1
}
