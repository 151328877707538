import axios from "axios" 
import Vue from 'vue'
let vue=new Vue()
axios.defaults.baseURL="http://192.168.1.8:8995"
//8096
axios.defaults.timeout=5000000000
let instance=axios.create()

export{
	 instance
}
