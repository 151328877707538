import Vue from 'vue'
import App from './App.vue'
import router from './router'
import element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'
import cookie from 'js-cookie'
import {instance} from "./utils/request.js"
import {instance1} from "./utils/request1.js"
import {instance2} from "./utils/request2.js"
Vue.config.productionTip = false
// Vue.use(Button)
// Vue.use(Select)
Vue.use(element)
Vue.prototype.$axios=instance
Vue.prototype.$axios1=instance1
Vue.prototype.$axios2=instance2
new Vue({
	router,
	store,
  render: h => h(App),
}).$mount('#app')
