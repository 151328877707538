<template>
	<div style="height: 100vh;background-color: #fff;width: 100vw;margin: 0 auto;border-radius: 8px;">
		<div
			style="height: 6vh;display: flex;align-items: center;justify-content: right;font-weight: 400;font-size: 14px;color: #2468F2;padding-right: 29px;">
			清空本页</div>
		<div style="display: flex;justify-content: space-between;">
			<div class="left">
				<div style="width: 40vw;height: 90vh;border: 1px solid #CCD9F2;background-color: #F2F6FA;">
					<div
						style="width: 40vw;height: 5vh;border-bottom: 1px solid #CCD9F2;display: flex;align-items: center;justify-content: space-between;">
						<div style="font-weight: bold;font-size: 17px;color: #2468F2;margin-left: 24px;">在线体验：</div>
						<!-- <div @click="recognition" v-loading.fullscreen.lock="fullscreenLoading"
							style="width: 85px;height: 3.5vh;background: #2468F2;margin-right: 17px;font-size: 14px;color: #FFFFFF;line-height: 3.5vh;text-align: center;">
							开始识别</div> -->
						<!-- <el-upload drag :http-request='httprequest'
							:before-upload="beforeAvatarUpload" class="avatar-uploader" action="#"
							:show-file-list="false">
						</el-upload> -->
						
						<div style="display: flex;">
							<el-upload
							  class="upload-demo" style="" :http-request='httprequest' :before-upload="beforeAvatarUpload" action="#" :show-file-list="false">
							  <div type="primary"
							  	style="width: 85px;height: 3.5vh;background: #2468F2;margin-right: 17px;font-size: 14px;color: #FFFFFF;line-height: 3.5vh;text-align: center;">
							  	点击上传</div>
							  <!-- <el-button style="margin-right: 17px;background: #2468F2;" size="small" type="primary">点击上传</el-button> -->
							</el-upload>
							<div @click="startRecordAudio"
								style="width: 85px;height: 3.5vh;background: #2468F2;margin-right: 17px;font-size: 14px;color: #FFFFFF;line-height: 3.5vh;text-align: center;">
								开始录音</div>
							<div @click="stopRecordAudio"
								style="width: 85px;height: 3.5vh;background: #2468F2;margin-right: 17px;font-size: 14px;color: #FFFFFF;line-height: 3.5vh;text-align: center;">
								停止录音</div>
						</div>
					</div>
					<h3 v-if="show" style="padding: 20px 20px 0 20px;width: 95%;">
						录音时长：{{ recorder.duration.toFixed(4) }}</h3>
					<el-input style="padding: 20px 20px 0 20px;width: 95%;" type="textarea" :rows="24"
						placeholder="请输入内容" v-model="input">
					</el-input>
					<div style="padding: 20px 20px 0 20px;width: 95%;">
						<div @click="recognition" v-loading.fullscreen.lock="fullscreenLoading"
							style="width: 85px;height: 3.5vh;background: #2468F2;font-size: 14px;color: #FFFFFF;line-height: 3.5vh;text-align: center;float: right;margin-top: 50px;">
							开始识别</div>
					</div>

				</div>
			</div>
			<div class="right" style="background-color: #F2F6FA;">
				<div
					style="width: 789px;height: 5vh;border-bottom: 1px solid #CCD9F2;display: flex;align-items: center;">
					<div style="font-weight: bold;font-size: 17px;color: #2468F2;margin-left: 24px;">结果分析：</div>
				</div>
				<div style="height: 11vh;">
					<div style="margin-top: 10px;margin-left: 20px;overflow-wrap: break-word;white-space: pre-line;">
						{{text}}
					</div>


				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import Recorder from 'js-audio-recorder';

	let recorder = new Recorder();
	export default {
		components: {

		},
		data() {
			return {
				show: false,
				recorder: new Recorder({
					sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
					sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
					numChannels: 1, // 声道，支持 1 或 2， 默认是1
					// compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
				}),
				fullscreenLoading: false,
				input: '',
				text: '未检测到相关信息',
			}
		},
		computed: {
			//this.resetting()
		},
		created() {

		},
		mount() {

		},
		mounted() {

		},
		destroyed() {

		},
		watch: {

		},
		methods: {
			httprequest() {},
			//获取图片路径
			beforeAvatarUpload(file) {
				var _this = this
				console.log(file)
				this.fullscreenLoading = true;
				var formData = new FormData()
				formData.append('file', file)
				// 27.185.55.104:8005/Asr
				
				this.$axios.post('Asr', formData).then(function(res) {
					console.log(res)
					_this.show = false
					_this.fullscreenLoading = false;
					_this.input = res.data.result[0].text
				})
			},
			startRecordAudio() {
				this.show = true
				this.input = ''
				Recorder.getPermission().then(
					() => {
						console.log("开始录音");
						this.recorder.start(); // 开始录音
					},
					(error) => {
						this.$message({
							message: "请先允许该网页使用麦克风",
							type: "info",
						});
						console.log(`${error.name} : ${error.message}`);
					}
				);
			},
			stopRecordAudio() {
				console.log("停止录音");
				this.recorder.stop();
				this.uploadWAVData()
			},
			//上传wav录音数据
			uploadWAVData() {
				var _this = this
				var wavBlob = this.recorder.getWAVBlob();
				// 创建一个formData对象
				console.log(wavBlob)
				var formData = new FormData()
				// 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
				const newbolb = new Blob([wavBlob], {
					type: 'audio/wav'
				})
				//获取当时时间戳作为文件名
				const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
				console.log(fileOfBlob)
				formData.append('file', fileOfBlob)
				this.$axios.post('Asr', formData).then(function(res) {
					console.log(res)
					_this.show = false
					_this.input = res.data.result
				})
			},

			recognition() {
				var _this = this
				this.fullscreenLoading = true;
				var params = {
					text: this.input
				}
				this.$axios1.post('llama_cpp', params).then(function(res) {
					console.log(res)
					_this.fullscreenLoading = false;
					_this.text = res.data.data

				})
			},

		},
	}
</script>

<style lang="less" scoped>
	.left {
		margin-left: 29px;
	}

	.right {
		width: 55vw;
		height: 90vh;
		margin-right: 29px;
		border: 1px solid #CCD9F2;
	}

	/deep/.el-input__inner {
		height: 4.2vh;
	}

	.selected {
		background-color: #216AFF;
		/* 选中的背景颜色 */
		color: white;
	}

	.selected1 {
		color: #646569;
	}

	/deep/.el-upload-dragger {
		width: 300px;
		height: 25vh;
		margin-left: 45px;
		margin-top: 6vh;
		//z-index: -1;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 300px;
		height: 25vh;
		line-height: 25vh;
		text-align: center;
	}
</style>